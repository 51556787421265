// ------ More Bet Details ------
  
  
  .leage-name{
    color:#F83300;
    
  }
  
  .bet-container{
    background: #161313;
  
  }
  
 

  .odd-border{
    border:0.4px solid #F83300; 
  }

  .selected-odd{
    background-color:#F83300 ;
  }

  .props-odd-font-size{
    font-size: 14px;
  }

  