// For All Devices ^^^^^^^^^^^^^^

/* Shine effect  start css*/
.shine-blue {
    color: rgb(0, 217, 255);
    text-align: center;
    animation: shine 1s ease-in-out infinite alternate;
}
  
@keyframes shine {
    from {
        text-shadow: 0 0 5px rgba(147, 247, 222, 0.685), 0 0 10px rgba(255, 255, 255, 0.671), 0 0 15px #e649007c, 0 0 20px #e6490050, 0 0 25px #e6490075, 0 0 30px #e649004d, 0 0 35px #e64900;
    }

    to {
        text-shadow: 0 0 10px rgba(138, 243, 208, 0.637), 0 0 15px #ff4da698, 0 0 20px #ff4da6, 0 0 25px #ff4da662, 0 0 30px #ff4da641, 0 0 35px #ff4da650, 0 0 40px #ff4da65e;
    }
}

/* Shine effect  end css*/


/* Orange Flash effect  start css*/

.flash-orange {
    -webkit-animation: flashorange 3000ms infinite;
    -moz-animation: flashorange 3000ms infinite;
    -o-animation: flashorange 3000ms infinite;
    animation: flashorange 3000ms infinite;
}
  
@-webkit-keyframes flashorange {
    0% {
        -webkit-box-shadow: 0 0 3px #e92300e7;
        background-color: #e92300b6;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #e923007c;
        background-color: #251915;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #e9230033;
        background-color: #e9230041;
    }
}
  
@keyframes flashorange {
    0% {
        -webkit-box-shadow: 0 0 3px #e92300e7;
        background-color: #e92300b6;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #e923007c;
        background-color: #251915;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #e9230033;
        background-color: #e9230041;
    }
}
  
/* Orange Flash effect end css*/

/* Blue Flash effect start css*/

.flash-blue {
    -webkit-animation: flashblue 3000ms infinite;
    -moz-animation: flashblue 3000ms infinite;
    -o-animation: flashblue 3000ms infinite;
    animation: flashblue 3000ms infinite;
}
  
@-webkit-keyframes flashblue {
    0% {
        -webkit-box-shadow: 0 0 3px #17a3b8ab;
        background-color: #17a3b8b4;
        font-size: 16px;
        font-weight: 500;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #17a3b883;
        background-color: #335b6163;
        font-size: 16px;
        font-weight: 500;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #17a3b84b;
        background-color: #03121493;
        font-size: 16px;
        font-weight: 500;
    }
}

@keyframes flashblue {
    0% {
        -webkit-box-shadow: 0 0 3px #17a3b8ab;
        background-color: #17a3b8b4;
        font-size: 16px;
        font-weight: 500;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #17a3b883;
        background-color: #335b6163;
        font-size: 16px;
        font-weight: 500;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #17a3b84b;
        background-color: #03121493;
        font-size: 16px;
        font-weight: 500;
    }
}
  
/* Blue Flash effect end css*/


/* Orange Highlight start css*/

.orange-highlight {
    -webkit-animation: orangehighlight 1300ms infinite;
    -moz-animation: orangehighlight 1300ms infinite;
    -o-animation: orangehighlight 1300ms infinite;
    animation: orangehighlight 1300ms infinite;
}
  
@-webkit-keyframes orangehighlight {
    0% {
        -webkit-box-shadow: 0 0 3px #e92200;
        background-color: #e92200;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #e92200;
        background-color: #302826;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #e92200;
        background-color: #e92200;
    }
}
  
@keyframes orangehighlight {
    0% {
        box-shadow: 0 0 3px #e92200;
        background-color: #e92200;
    }
    50% {
        box-shadow: 0 0 8px #e92200;
        background-color: #302826;
    }
    100% {
        box-shadow: 0 0 3px #e92200;
        background-color: #e92200;
    }
}

/* Orange Highlight start css*/


/* Red Highlight start css*/

.red-highlight {
    -webkit-animation: redhighlight 1300ms infinite;
    -moz-animation: redhighlight 1300ms infinite;
    -o-animation: redhighlight 1300ms infinite;
    animation: redhighlight 1300ms infinite;
}
  
@-webkit-keyframes redehighlight {
    0% {
        -webkit-box-shadow: 0 0 3px #9a1700be;
        background-color: #9a1700be;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #9a1700be;
        background-color: #302826;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #9a1700be;
        background-color: #9a1700be;
    }
}
  
@keyframes redhighlight {
    0% {
        -webkit-box-shadow: 0 0 3px #9a1700be;
        background-color: #9a1700be;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #9a1700be;
        background-color: #302826;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #9a1700be;
        background-color: #9a1700be;
    }
}
  
/* Red Highlight end css*/


/* Blue Highlight start css*/

.blue-highlight {
    -webkit-animation: bluehighlight 1300ms infinite;
    -moz-animation: bluehighlight 1300ms infinite;
    -o-animation: bluehighlight 1300ms infinite;
    animation: bluehighlight 1300ms infinite;
}
  
@-webkit-keyframes bluehighlight {
    0% {
        -webkit-box-shadow: 0 0 3px #005ce6;
        background-color: #005ce6;
    }
    50% {
        -webkit-box-shadow: 0 0 8px #005ce6;
        background-color: #302826;
    }
    100% {
        -webkit-box-shadow: 0 0 3px #005ce6;
        background-color: #005ce6;
    }
}
  
@keyframes bluehighlight {
    0% {
        box-shadow: 0 0 3px #005ce6;
        background-color: #005ce6;
    }
    50% {
        box-shadow: 0 0 8px #005ce6;
        background-color: #302826;
    }
    100% {
        box-shadow: 0 0 3px #005ce6;
        background-color: #005ce6;
    }
}
  
/* Blue Highlight end css*/



// ***************** Bet Reports ****************
.settled-datepicker{
    padding: 5px 5px;
    background: #ec4561;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 12px;
}

.inline-datepicker-container{
    display: flex;
    margin-top: -46px;
    margin-bottom: 15px;
    justify-content: flex-end;
}


//********* Header Menu Nav link ************//
.list-nav {
    position: absolute;
    left: 21%;
    top: 14px;
    width:54%;
    display: flex;
}

.list-item-nav {
    font-weight: 500;
    color: aliceblue;
    margin-right: 20px;
    height:30px;
    padding: 5px 0;
}

.list-item-nav:hover {
    color: #d52b00;
    border-bottom: 1px solid #d52b00;
    width: fit-content;
}

.list-item-nav-active {
    color: #d52b00;
    font-weight: 500;
    height:30px;
    width: fit-content;
    border-bottom: 1px solid #d52b00;
    margin-right: 20px;
    padding: 5px 0;
}

.list-right {
    position: absolute;
    right: 255px;
    top: 7px;
}

.menu-extras-sport{
    padding-right: 3px;
}

.menu-item-head {
    display: flex;
    display: flex;
    color: #b3b1b1;
    letter-spacing: .2px;
    margin-top: 2px;
    font-size: 13px;
}

.sportbook-container-fluid {
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
}

//********* left side css ************//
.side-bar-inactive,
.side-bar {
    margin-top: 80px;
    width: 17%;
    padding: 0 13px;
}

.side-hader-option {
    padding:10px 0;
    padding-top: 0px;
    margin: 0;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.continue-box-inactive,
.continue-box {
    display: none;
}

.head-button {
    background-color: #302d2d;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 9px;
    margin-right: 7px;
}

.head-button-active {
    background-color: #d52b00;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 9px;
    margin-right: 7px;
}

.side-button {
    padding: 8px 0;
    width:-webkit-fill-available;
    background-color: #000000;
    color: white;
    height: 40px;
    text-align: justify;
    border-radius: 5px;
    border: none;
    display: flex;
    text-transform: uppercase;
}

.side-list {
    background-color: #0b0b0b;
    width: -webkit-fill-available;
    border-radius: 5px;
    margin-bottom: 15px;
}

.side-check {
    padding: 5px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: small;
}

.side-right-inactive,
.side-right {
    margin-top: 71px;
    background-color: #0b0b0b;
    width: 28%;
    padding: 10px 13px;
}

//right side bet card css
.bet-title{
    padding: 6px 20px !important;
}

.bet-card {
    border-radius: 10px !important;
    background-image: linear-gradient(#0b0b0b, #000000);
    border: 1px solid #962c11;
    width: 100%;
    color: aliceblue;
}

.bet-card-header {
    background-color: #161313;
    color: white;
    border-bottom: 1px solid #962c11;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.hader-text {
    font-size: 14px;
    font-weight: 200;
}

.bet-card-multi {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}

.bet-text {
    margin-top: -13px;
}

.card-input::placeholder {
    color: #645e5e !important;
}

.left-space {
    margin-left: 35px;
}

.btn-outline-box {
    border-radius: 5px;
    background-color: #d52b00 ;
    border: none;
    box-shadow: 1px 2px #ff8975;
    color: white;
    width: 35%;
    padding: 5px;
    font-size: 12px;
}

.btn-outline-box:hover {
    background-color: #e92200 !important;
    box-shadow: 1px 2px #ff8975;
}

.max-all-bet{
    box-shadow: 1px 2px #ffffff !important;
    font-size: 13px;
    font-weight: 500;
    color: black;
    background: yellow;
    width: 50%;
}

//league css
.lague-box-inactive,
.lague-box {
    margin-top: 71px;
    background-color: #000000;
    width: 55%;
    padding: 0 10px;
    color: aliceblue;
}

.league-filter-box {
    width: 100%;
    background-color: #0b0b0b;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 0px 0px #2b2a2a;
    margin-bottom: 15px;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: auto;
    display:none;
}

.league-title-btn {
    background-color: #161313;
    border: none;
    color: aliceblue;
}

.refresh-btn {
    background-color: #161313;
    width: auto;
    height: 30px;
    color: aliceblue;
    margin-right: 20px;
    border: none;
}

//********* Search box *********
.search-box {
    display: flex;
    height: 35px;
    width: 45%;
    border-radius: 5px;
    border: 1px solid #d52b00;
}

.search-input {
    width: 80%;
    height: 30px;
    border-radius: 5;
    background-color: #0b0b0b;
    border: none;
    margin: 2px;
    color: aliceblue;
}

.search-input::placeholder {
    font-weight: 200;
    opacity: 1;
    color: red;
    padding-left: 5px;
}

.icon-box {
    height: 34px;
    width: 40px;
    border: 1px solid #e92200;
    border-width: 0px 1px 1px 0px;
    background-color: #ccc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0b0b0b;
}

.icon-box-icon {
    background-color: #0b0b0b;
}

//************ filter button ************
.filter-btn {
    border-radius: 5px !important;
    background-color: #000000 !important;
    border: 0.1px solid #e92200;
    padding: 5px !important;
    font-size: 14px !important;
    color: #ffffff;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 35px;
}

//*********** Dropdown styling ********
.dropdown-box {
    height: auto;
}

.dropdown-box-container {
    height: 35px;
    background-color: #0b0b0b;
    color: aliceblue;
    border: 1px solid #d52b00;
    border-radius: 5px;
    padding-left: 6px;
    padding-right: 15px;
}

.dropdown-option {
    padding: 10px;
    background-color: #0b0b0b;
    color: aliceblue;
    border: 1px solid #d52b00;
}

.league-button {
    width: 100%;
    background-color: #161313;
    border: 0.1px solid #d52b00;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    min-height: 30px;
    margin-top: 8px;
    padding-right: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.league-head {
    display: flex;
    flex-direction: row;
    background-color: #161313;
    border: none;
    justify-content: space-between;
    width: 100%;
    padding-top: 9px;
    padding-left: 9px;
    padding-right: 20px;
    color: #ffffff;
    border-top-left-radius: 5px;
}

.link-box {
    display: flex;
    margin-top: -15px;
    padding-left: 8px;
    padding-bottom: 10px;
}

.link-btn {
    color: aliceblue;
    background-color: #161313;
    margin-bottom: 4px;
    border: none;
}

.link-btn:hover {
    color: #d52b00;
    background-color: #161313;
    margin-bottom: 4px;
    content: "";
    display: block;
    border-bottom: 2px solid #d52b00;
}

.link-btn-active {
    color: #d52b00;
    background-color: #161313;
    margin-bottom: 4px;
    border: none;
}

.link-btn-active:after {
    content: "";
    display: block;
    border-bottom: 2px solid #d52b00;
}

.league-list {
    background-color: #0b0b0b;
    width: 100%;
    margin-top: -5px;
    border: 0.1px solid #d52b00;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
}

.league-info {
    width: 100%;
    padding: 10px;
    background-color: #161313;
    margin-top: 10px;
}

.league-info-head {
    display: flex;
    flex-direction: column;
}

.league-head-title {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: 13px;
    padding-left: 50%;
}

.league-head-title pre{
    font-size: 9px;
    color: #ffffff;
}

.match-time {
    letter-spacing: 1px;
    font-size: 13px !important;
    margin-bottom: 5px;
    color: #6c757d !important;
    font-weight: 500;
}

.match-location {
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px !important;
}


// Team Card css
.team-logo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.odds-responsive,
.team-responsive {
    width: 50%;
}

.odd-button,
.odd-disable-button {
    border-radius: 5px !important;
    border: .5px solid #e92200;
    padding: 5px !important;
    text-align: center;
    font-size: 12px !important;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.odd-disable-button {
    background-color: #1a1919;
    border: 0.1px solid #550e02;
}

.odd-button-active {
    background-color: #d52b00;
    border-color: #d52b00 !important;
}

.team-name {
    font-size: 15px;
    letter-spacing: 0.4px;
}

.close-button-right {
    display: none;
}

.bet-option-grid {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
}

.match-info-warn,
.match-info{
    font-size: 12px;
    padding: 3px 8px;
    border: 1px solid #5f1a00;
    border-radius: 5px;
    text-align: end;
    text-wrap: nowrap;
    margin-right: 2px;
    color: #d73c00;
}

.match-info-warn{
    border: 1px solid #684e00 !important;
    color: #ffc109 !important;
}

.match-status{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

//*************** IMage Class ********
.img-football {
    background: url('../../Sportbook/Img/football.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.img-baseball {
    background: url('../../Sportbook/Img/baseball.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.img-basketball {
    background: url('../../Sportbook/Img/basketball.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.img-soccer {
    background: url('../../Sportbook/Img/soccer.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.img-hockey {
    background: url('../../Sportbook/Img/hockey.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.img-boxing {
    background: url('../../Sportbook/Img/boxing.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.img-cricket {
    background: url('../../Sportbook/Img/cricket.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.bet-button-box-inactive,
.bet-button-box {
    display: none;
}

.no-tbl-header {
    padding-top: 15px;
}


.club-title-sport {
    color: #dbdbdb;
    margin-left: 6px;
    font-size: 12px;
    border-radius: 3px;
    border: 0.2px solid gray;
    padding: 1px 2px;
    letter-spacing: 0.3px;
}

//************ Bottom Right card *****
.bet-bottom {
    padding: 5px;
    text-align: center;
}

.remove-selection-btn {
    background-color: #0b0b0b;
    border: none;
    color: #d52b00;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
}

.bet-place-btn {
    height: 35px;
    justify-content: center;
    align-items: center;
    background-color: #d52b00;
    border-radius: 5px;
    border: none;
    width: 85%;
    color: aliceblue;
    margin-bottom: 10px;
    margin-top: 10px;
}

.bet-bottom-text {
    color: aliceblue;
    font-size: 18px;
    margin-top: -10px;
    font-weight: 500;
}

.px-10per {
    padding-left:10%;
    padding-right:10%;
}

//***************** Bet history table ****
.bet-history-container {
    background-color: #161313;;
}

.bet-history-top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bet-name-box {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.bet-title-hi {
    font-size: 18px;
    font-weight: 800;
    text-transform: capitalize;
}

.bet-subtitle-his {
    font-size: 10px;
    color: whitesmoke;
    text-transform: uppercase;
}

.history-match-container {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-top: -8px;
}

.horiz-line {
    width: 1px;
    height: 40px;
    background-color: aliceblue;
    margin-left: 10px;
    margin-right: 10px;
}

// .history-team-box {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

.history-team-list {
    display: flex;
    flex-direction: column;
}

.light-text-center {
    padding-top: 20px;
    text-align: end;
    padding-left: 30px;
    align-self: center;
}

.horiz-right-text {
    font-size: 13px;
    text-align: center;
}

.bet-subtitle-text {
    font-size: 11px;
    color: whitesmoke;
    text-transform: uppercase;
}

.bet-subtitle-amount {
    color: #0eaf36f6;
    font-weight: bold;
    font-size: 16px;
}

.value-box-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid #d52b00;
    border-bottom: 1px solid #d52b00;
}

.pd-top {
    padding-top: 10px;
    padding-bottom: 10px;
}

.bet-options {
    display: flex;
    align-items: stretch;
}

.bet-options-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
}

.bet-expand-odd{
    font-size: 20px;
    min-width: 26px;
}

.icon-bet-yes {
    color: #19f05a;
    font-size: 20px;
}

.icon-bet-yes-sm {
    color: #19f05a;
    font-size: 14px !important;
}

.icon-bet-no {
    color: #d30505;
    font-size: 20px;
}

.icon-bet-no-sm {
    color: #d30505;
    font-size: 14px !important;
}

.icon-bet-pending {
    color: #5a9994;
    font-size: 20px;
}

.icon-bet-tie {
    color: #cfc500;
    font-size: 20px;
}

.bet-option-title {
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: capitalize;
}

.bet-option-subtitle {
    font-size: 12px;
    color: whitesmoke;
    text-transform: uppercase;
}

.bet-line {
    width: 1px;
    height: 96%;
    background-color: #898a89;
    margin-left: 10px;
}

.check-text-style {
    color: aliceblue;
    font-size: 14px;
}

// Odds data start 
// .team-seperator {
//     display: flex;
//     align-items: center;
//     height: 0;
//     line-height: 0;
//     margin-left: 1px;
//     font-size: 20px;
//     color: #005ce6;
//     padding: 7px 0px 10px 0px;
// }

.dash-line {
    width: 30%;
    height: 0.5px;
    background-color: #005ce6b3;
    margin-left: 5px;
}

// odds data end

// For Big Desktop Only
@media (min-width: 1065px) and (max-width: 1250px) {
    .match-status{
        margin-bottom: 12px;
    }

    .league-head-title {
        justify-content: space-between;
        font-size: 14px;
        padding: 0 7px;
    }
    
    .league-head-title pre{
        font-size: 12px;
    }

    .team-responsive{
        width: 100%;
    }

    .odds-responsive {
        margin-top: 10px;
        width: 100%;
    }
}

// For Normal Desktop Only
@media (min-width: 992px) and (max-width: 1064px) {
    .list-nav {
        width:47%;
    }

    .match-status{
        margin-bottom: 12px;
    }

    .league-head-title {
        justify-content: space-between;
        font-size: 14px;
        padding: 0 7px;
    }
    
    .league-head-title pre{
        font-size: 11px;
    }

   
    .team-responsive {
        width: 100%;
    }

    .odds-responsive {
        margin-top: 10px;
        width: 100%;
    }

    .bet-card {
        align-self: center;
    }

    .bet-card-header {
        font-size: 12px;
    }

    .close-button-right {
        display: none;
    }
}

// For Tablet ^^^^^^^^^^^^^^
@media (min-width:768px) and (max-width: 991px) {

    .league-filter-box{
        display:flex
    }

    .club-title-sport {
        display: none;
    }

    .list-nav {
        left: 10%;
        width:51%;
    }

    .menu-extras-sport {
        position: absolute;
        top: 1px;
        right: 0px;
        display: flex;
        padding-right: 13px;
    }

    .continue-box-inactive {
        display: none;
    }

    .continue-box {
        width: 100%;
        padding: 10px;
        margin-top: 15px;
        display: block;
    }

    .bet-btn,
    .continue-button {
        background-color: #d52b00;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
        padding-right: 35px;
        color: aliceblue;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        position: fixed;
        right: 27px;
        bottom: 20px;
    }

    .lague-box-inactive {
        display: none;
    }

    .lague-box {
        width: 100%;
    }

    .list-right {
        right: 167px;
    }

    .side-bar-inactive {
        display: none;
    }

    .side-bar {
        width: 100%;
    }

    .date-text {
        font-size: 12px;
        text-align: left;
        margin-left: -8px;
        margin-bottom: 5px;
    }

    .side-right-inactive {
        display: none;
    }

    .side-right {
        width: 100%;
        padding: 10px 20px;
    }

    .bet-card {
        width: 100%;
    }

    .filter-btn {
        border-radius: 5px !important;
        background-color: #000000 !important;
        border: 0.1px solid #e92200;
        padding: 5px !important;
        font-size: 12px !important;
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 10px !important;
        padding-right: 10px !important;
        height: 35px;
    }

    .dropdown-box-container {
        height: 35px;
        background-color: #0b0b0b;
        color: aliceblue;
        border: 1px solid #d52b00;
        border-radius: 5px;
        padding-left: 6px;
        padding-right: 5px;
        font-size: 12px;
    }

    .dropdown-option {
        padding: 5px;
        background-color: #0b0b0b;
        color: aliceblue;
        border: 1px solid #d52b00;
        font-size: 12px;
    }

    .icon-box {
        height: 34px;
        width: 25px;
    }

    .search-input {
        width: 80%;
        font-size: 12px;
    }

    .search-input::placeholder {
        font-weight: 100;
        padding-left: 5px;
        font-size: 12px;
    }

    .close-button-right {
        border-radius: 5px !important;
        background-color: #000000 !important;
        border: 0.1px solid #e92200;
        color: #d52b00;
        display: block;
        height: 25px;
    }

    .bet-button-box-inactive {
        display: none;
    }

    .bet-button-box {
        display: block;
        width: 100%;
        align-items: end;
        padding: 10px;
        margin-top: 20px;
        float: right;
    }
}

// For Mobile ^^^^^^^^^^^^^^
@media (min-width:360px) and (max-width: 767px) {
    .league-filter-box{
        display:flex
    }

    .app-logo-sport{
        display: none;
    }

    .club-title-sport {
        display: none;
    }

    .lague-box-inactive {
        display: none;
    }

    .lague-box {
        margin-top: 61px;
        width: 100%;
    }

    .list-nav {
        background-color: #0b0b0b;
        display: flex;
        left: -12px;
        padding: 5px 13px;
        position: relative;
        top: 2px;
        width: 110%;
    }

    .continue-box-inactive {
        display: none;
    }

    .continue-box {
        width: 100%;
        padding: 10px;
        margin-top: 15px;
        display: block;
    }

    .bet-btn,
    .continue-button {
        background-color: #d52b00;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
        padding-right: 35px;
        color: aliceblue;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        position: fixed;
        right: 27px;
        bottom: 20px;
    }

    .list-right {
        right: 155px;
    }

    .menu-extras-sport {
        position: absolute;
        top: 1px;
        right: 0px;
        display: flex;
        padding-right: 13px;
    }

    .sportbook-container-fluid {
        margin-top: 45px;
    }

    .side-bar-inactive {
        display: none;
    }

    .side-bar {
        width: 100%;
    }

    .match-status{
        margin-bottom: 12px;
    }

    .league-head-title {
        justify-content: space-between;
        font-size: 14px;
        padding: 0 7px;
    }

    
    .team-responsive {
        width: 100%;
    }

    .odds-responsive {
        margin-top: 10px;
        width: 100%;
    }

    .date-text {
        font-size: 12px;
        text-align: left;
        margin-left: -8px;
        margin-bottom: 5px;
    }

    .side-right-inactive {
        display: none;
    }

    .side-right {
        width: 100%;
        padding: 10px 13px;
    }

    .bet-card {
        width: 100%;
    }

    .filter-btn {
        border-radius: 5px !important;
        background-color: #000000 !important;
        border: 0.1px solid #e92200;
        padding: 5px !important;
        font-size: 12px !important;
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 10px !important;
        padding-right: 10px !important;
        height: 35px;
    }

    .dropdown-box-container {
        height: 35px;
        background-color: #0b0b0b;
        color: aliceblue;
        border: 1px solid #d52b00;
        border-radius: 5px;
        padding-left: 6px;
        padding-right: 5px;
        font-size: 12px;
    }

    .dropdown-option {
        padding: 5px;
        background-color: #0b0b0b;
        color: aliceblue;
        border: 1px solid #d52b00;
        font-size: 12px;
    }

    .icon-box {
        height: 34px;
        width: 25px;
    }

    .search-input {
        width: 80%;
        font-size: 12px;
    }

    .search-input::placeholder {
        font-weight: 100;
        padding-left: 5px;
        font-size: 12px;
    }

    .close-button-right {
        border-radius: 5px !important;
        background-color: #000000 !important;
        border: 0.1px solid #e92200;
        color: #d52b00;
        display: block;
        height: 25px;
    }

    .bet-button-box-inactive {
        display: none;
    }

    .bet-button-box {
        display: block;
        width: 100%;
        align-items: end;
        padding: 10px;
        margin-top: 20px;
        float: right;
    }


    //******* bet style***
    .light-text-center {
        padding-left: 0px;
    }

    .bet-history-top-box {
        padding-right: 0px;
    }
}

// For Small Mobile Only ^^^^^^^^^^^^^^
@media (max-width: 359px) {
    .app-logo-sport{
        display: none;
    }
    
    .club-title-sport {
        display: none;
    }

    .lague-box-inactive {

        display: none;
    }

    .lague-box {
        margin-top: 61px;
        width: 100%;
    }

    .league-filter-box {
        width: 100%;
        display: flex;
    }

    .list-nav {
        background-color: #0b0b0b;
        display: flex;
        left: -12px;
        padding: 5px 13px;
        position: relative;
        top: 2px;
        width: 110%;
    }

    .list-right {
        right: 45%;
    }

    .menu-extras-sport {
        position: absolute;
        top: 1px;
        right: 0px;
        display: flex;
        padding-right: 13px;
    }

    .sportbook-container-fluid {
        margin-top: 45px;
    }

    .side-bar-inactive {
        display: none;
    }

    .continue-box-inactive {
        display: none;
    }

    .continue-box {
        width: 100%;
        padding: 10px;
        margin-top: 15px;
        display: block;
    }

    .bet-btn,
    .continue-button {
        background-color: #d52b00;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
        padding-right: 35px;
        color: aliceblue;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        position: fixed;
        right: 27px;
        bottom: 20px;
    }

    .side-bar {
        width: 100%;
    }

    .match-status{
        margin-bottom: 12px;
    }
    
    .league-head-title {
        justify-content: space-between;
        font-size: 14px;
        padding: 0 7px;
    }

    .team-responsive {
        width: 100%;
    }

    .odds-responsive {
        margin-top: 10px;
        width: 100%;
    }

    .date-text {
        font-size: 12px;
        text-align: left;
        margin-left: -8px;
        margin-bottom: 5px;
    }

    .side-right-inactive {
        display: none;
    }

    .side-right {
        width: 100%;
        padding: 10px 13px;
    }

    .bet-card {
        width: 100%;
    }

    .filter-btn {
        border-radius: 5px !important;
        background-color: #000000 !important;
        border: 0.1px solid #e92200;
        padding: 5px !important;
        font-size: 10px !important;
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
        height: 35px;
    }

    .dropdown-box-container {
        height: 35px;
        background-color: #0b0b0b;
        color: aliceblue;
        border: 1px solid #d52b00;
        border-radius: 5px;
        padding-left: 6px;
        padding-right: 5px;
        font-size: 12px;
    }

    .dropdown-option {
        padding: 5px;
        background-color: #0b0b0b;
        color: aliceblue;
        border: 1px solid #d52b00;
        font-size: 12px;
    }

    .icon-box {
        height: 34px;
        width: 25px;
    }

    .search-input {
        width: 80%;
        font-size: 12px;
    }

    .search-input::placeholder {
        font-weight: 100;
        padding-left: 5px;
        font-size: 12px;
    }

    .close-button-right {
        border-radius: 5px !important;
        background-color: #000000 !important;
        border: 0.1px solid #e92200;
        font-size: 14px !important;
        color: #d52b00;
        display: block;
        height: 34px;
    }

    .bet-button-box-inactive {
        display: none;
    }

    .bet-button-box {
        display: block;
        width: 100%;
        align-items: end;
        padding: 10px;
        margin-top: 20px;
        float: right;
    }

    //******* bet style***
    .light-text-center {
        padding-left: 0px;
    }

    .bet-history-top-box {
        padding-right: 0px;
    }

    // .team-seperator{
    //     display: none !important;
    // }
}
