// Comman ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
.full-logo {
    width: 10rem;
}

.BG-valuebet {
    background-color: #0C0C0C;
    background-image: url(../UserManagement/images/landing/bg-gaming.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.cust-nav {
    position: fixed;
    width: 100%;
    z-index: 10;
    background: linear-gradient(90deg, #1b1a1a, #282525, #252222, #181818) !important;
}

.club_buttons {
    border-radius: 5px;
    background-color: #e03f00;
    color: white;
    width: 185px;
    letter-spacing: 0.7px;
    font-weight: bold;
    font-size: 16px;
    padding: 9px 10px;
    margin: 2px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 42px;
    margin-top: 44px;
}



// hero css start
.vb_detail h3.heading_v {
    font-size: 46px;
    font-weight: 600;
}

.vb_detail li.right_carousel_img img {
    height: 100%;
    object-fit: contain;
}

.vb_detail li.right_carousel_img {
    width: 100%;
    height: 470px;
    text-align: right;
}

section.vb_all_detail {
    padding-top: 50px;
}

.vb_detail span#button-vb img {
    margin-left: 8px;
}

.vb_detail span#button-vb img:hover {
    margin-left: 12px;
    transition: 0.2s;
}

li {
    list-style: none;
}

span.text_orange {
    color: #e03f00;
    font-weight: 600;
}

.vb_detail p.desc_v {
    font-size: 18px;
    border-left: 2px solid #e03f00;
    padding: 0 0 0 14px;
    color: #CECECE;
}

.vb_detail .carousel-control-next,
.carousel-control-prev {
    display: none;
}

.vb_detail .carousel-indicators [data-bs-target] {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border-color: 1px solid orange;
    border: 1px solid #e03f00;
}

.vb_detail .carousel-indicators {
    bottom: -30px;
    right: 0;
    left: 0;
    text-align: right;
    justify-content: end;
    margin-right: 14px;
}

.vb_detail button.active {
    background: red !important;
}

// we are valubet section css start
section.valubett_intro {
    padding-top: 125px;
}

.valubett_intro .main_heading {
    margin-top: 45px;
}

.valubett_intro .left_section_image img {
    width: 530px;
}

.valubett_intro p.desc_v {
    font-size: 18px;
    text-align: right;
    border-width: 0 2px 0 0px;
    border-style: solid;
    border-color: #e03f00;
    padding: 0px 14px 0 47px;
    color: #CECECE;
    line-height: 32px;
}

.valubett_intro h3.heading_v {
    font-size: 46px;
    font-weight: 600;
    text-align: right;
    margin-bottom: 25px;
}

// play our challenges css start

.our_challenges {
    padding-top: 125px;
}

.our_challenges .right_section_image {
    text-align: right;
}

.our_challenges .right_section_image img {
    margin-top: -40px;
    height: 335px;
}

.our_challenges .club_buttons {
    width: 166px;
    margin-top: 40px;
}

.our_challenges p.desc_v {
    font-size: 18px;
    text-align: left;
    border-width: 0 0px 0 2px;
    border-style: solid;
    border-color: #e03f00;
    padding: 0px 0px 0 14px;
    color: #CECECE;
    line-height: 32px;
    margin-bottom: 25px;
}

.our_challenges h3.heading_v{
    font-size: 46px;
    font-weight: 600;
    text-align: left;
}


// Reward Section
.reward-section {
    padding-top: 190px;
}

.reward-section .left_section_image img {
    margin-top: -40px;
    height: 335px;
}

.reward-section .learn_button_vb{
    float: right;
}

.reward-section .main_heading {
    margin-top: 45px;
}

.reward-section .club_buttons {
    width: 166px;
    margin-top: 40px;
}

.reward-section p.desc_v {
    font-size: 18px;
    text-align: right;
    border-width: 0 2px 0 0px;
    border-style: solid;
    border-color: #e03f00;
    padding: 0px 14px 0 47px;
    color: #CECECE;
    line-height: 32px;
}

.reward-section h3.heading_v{
    font-size: 46px;
    font-weight: 600;
    text-align: right;
}



// jpon club css start
section.join_club {
    padding-top: 280px;
}

.club_card {
    background-clip: padding-box;
    margin-bottom: 30px;
    border-radius: 14px;
    border: 3px solid #e03f00;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100%;
}

.join_club h5.card-title {
    text-align: center;
    font-size: 46px;
    max-width: 60%;
    margin: 0 auto;
    font-weight: 600;
    color: #CECECE;
}

.join_club p.card-text {
    font-size: 18px;
    color: #CECECE;
    text-align: center;
    padding: 16px 38px 0px 38px;
    line-height: 32px;
}

.club_image {
    margin-top: -185px;
}

.join_club .clubs_button_vb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0px 60px 0px;
}

.join_club .club_buttons {
    border-radius: 5px;
    background-color: #e03f00;
    color: white;
    width: 166px;
    letter-spacing: 0.7px;
    font-weight: bold;
    font-size: 16px;
    padding: 9px 10px;
    margin: 2px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 42px;
    margin-top: 40px;
}


// about gaming css start
section.about_gaming {
    margin-top: 95px;
    padding: 66px 0px 100px;
    background-image: url(../UserManagement/images/landing/benefits_background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about_gaming .logo_image {
    text-align: right;
}

.about_gaming h3.heading_v {
    font-size: 46px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 25px;

}

.about_gaming p.desc_v {
    font-size: 18px;
    text-align: left;
    border-width: 0 0px 0 2px;
    border-style: solid;
    border-color: #e03f00;
    padding: 0px 0px 0 14px;
    line-height: 32px;
}

.about_gaming .logo_image img {
    position: relative;
    right: 0;
}

p.realtime_v {
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.sportsbook_label .sportsbook_label_line {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 40px;
}

.sportsbooks-labels__item {
    align-items: center;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    height: 69px;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
    transition: .4s;
    width: 190px;
}

section.about_gaming .club_buttons {
    width: 166px;
}

.sportsbooks-labels__item.barstool img {
    width: 172px;
    height: 71px;
    object-fit: contain;
}

.sportsbooks-labels__item.barstool {
    width: 200px;
    height: 84px;
}

// footer css start-----------------////
footer.vb_fppter {
    padding: 31px 0px;
}

footer.vb_fppter p.copyright.text-center {
    margin: 0;
    padding: 36px 10px;
    font-size: 18px;
    border: 2px 0px 0px 0px;
    border-style: solid;
    border-width: 1px 0px 1px 0px;
    border-color: #707070;
}

.vb_fppter .footer_logo img {
    width: 196px;
    margin-top: 20px;
}

.vb_fppter ul.ftco-footer-social.p-0 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 38px;
}

.vb_fppter li.ftco-animate img {
    margin-left: 14px;
    width: 52px;
}

.vb_fppter .footer_menu a,
.vb_fppter .footer_menu span {
    margin: 0 3px;
    letter-spacing: 1px;
    font-size: 18px;
    color: #CECECE;
    letter-spacing: 2px;
}

.vb_fppter p.footer_menu a {
    text-decoration-line: none;
}


// Other Public Page Styling ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

.page-main-heading{
    margin-top: 60px;
}

.thank-you-card{
    min-height: 100vh;
    padding: 0 260px;
    clip-path: polygon(20% 0, -15% 100%, 100% 100%, 100% 0%, 100% 0);
}

.sub-card{
    height: 200px;
}

.reguler-fonts{
    font-size: 16px;
    padding: 0px 0px 0 14px;
    color: #CECECE;
    line-height: 32px;
    margin-bottom: 25px;
}

.reguler-heading{
    line-height: 32px;
    margin-bottom: 25px;
}

.price-tabel-fonts{
    font-size: 14px ;
}

.btn-live{
    position: absolute;
    padding: 5px;
    top: -2px;
    right: -2px;
    border-radius: 50%;
}

.pricing-height{
    min-height: 150px;
}

.pretty-card{
    width: 70%;
}