/* ==============
  Helper Classes
===================*/


// Margin Padding
.p-0 {
  padding: 0;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.m-0 {
  margin: 0;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-12 {
  padding-right: 12px;
}
.p-r-15 {
  padding-right: 15px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}


// Line Height
.l-h-23{
  line-height: 23px;
}

.l-h-30{
  line-height: 30px;
}

.l-h-34 {
  line-height: 34px;
}


// Squire Size
.thumb-sm {
  height: 32px;
  width: 32px;
}
.thumb-md {
  height: 48px;
  width: 48px;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}


// Font Weight
.font-500{
  font-weight: 500;
}

.font-600{
  font-weight: 600;
}

.header-title {
  font-size: 16px;
}

.force-vertical-center{
  vertical-align: -webkit-baseline-middle;
}


// Width
.w-40{
  width: 40%;
}

.w-96{
  width: 96%;
}

.w-100px{
  width: 100px;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.min-width-170{
  min-width: 170px;
}

.min-width-290{
  min-width: 290px;
}


// Height
.min-vh-50{
  min-height: 50vh;
}

.height-24{
  height: 24px;
}

.height-28{
  height: 28px;
}

.mh-115px{
  min-height: 115px;
}


// Size
.font-size-10px {
  font-size: 10px;
}

.font-size-11px {
  font-size: 11px;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-13px {
  font-size: 13px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-15px {
  font-size: 15px;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-22px {
  font-size: 22px;
}

.font-size-24px {
  font-size: 24px;
}

.font-size-30px-imp {
  font-size: 30px !important;
}
