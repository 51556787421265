/* Root CSS */
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu&display=swap');


html{
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

body{
  color: white;
  font-size: 0.90rem;
  font-family: 'Ubuntu', 'Roboto', sans-serif;
}

/* Recaptcha Customisations */
.grecaptcha-badge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  visibility: hidden;
}



/* Popup CSS */
.popup-content {
  background: #000000 !important;
  border: 1px solid #f30000 !important;
  color: white !important;
  width: auto !important;
}

@media screen and (min-width: 450px) {
  .popup-container {
    width: 420px;
  }
}

@media only screen and (max-width: 450px) {
  .popup-content {
    width: 80% !important;
  }
}


.e-tip-content {
  padding: 1px 5px;
  color: #ffffff;
  background: black;
  font-weight: 500;
  border: 2px solid white;
}



/* Table css */

.rdt_TableFooter {
  height: 100px;
  color: red;
}

.rdt_ExpanderRow {
  background-color: #161313 !important;
}

.customTable .rdt_Pagination {
  margin-top: 36px;
}

.tableFooter .iECIgJ {
  overflow-x: hidden;
}

.no-tbl-header #row--1 {
  background-color: #e03f00;
}

/* overflow hidden used to avoid unnecessary scrolls (bug fix) */
.responsive-expand .rdt_TableBody{
  overflow: hidden;
}

/* overflow hidden used to avoid unnecessary scrolls (bug fix) */
.responsive-expand .rdt_Table{
  overflow: hidden;
}

.rdt_TableHeadRow {
  background: #e03f00 !important;
}

.no-tbl-header .rdt_TableHeader {
  display: none;
}

.head-green .rdt_TableHeadRow{
  background: green !important;
}

.head-green .rdt_TableHeadRow .rdt_TableCol{
  color: white !important;
}

.head-yellow .rdt_TableHeadRow{
  background: yellow !important;
}

.head-yellow .rdt_TableHeadRow .rdt_TableCol{
  color: black !important;
}

.split-header .rdt_TableHeadRow > div:first-child{
  background: yellow;
  margin-bottom: 4px;
  margin-top: 33px;
}

.split-header .rdt_TableHeadRow .rdt_TableCol {
  div{
    width: 100%;
  }
  .tbl-head-second-row{
    padding: 10px 0px 10px 15px;
    margin-top: 5px;
    background-color: yellow;
    color: #000000;
  }
  .tbl-head-first-row{
    padding-left: 15px;
  }
  padding: 0;
}

/* .hide-table-header .rdt_TableHead {
  height:0;
  font-size: 0;
  background-color: none !important;
  background: none !important;
}
.hide-table-header .rdt_TableHeadRow {
  height:0;
  font-size: 0;
  background-color: none !important;
  background: none !important;
}
.hide-table-header .rdt_TableCol {
  height:0;
  font-size: 0;
  background-color: none !important;
  background: none !important;
} */




/* Notification Timeline CSS */

.notification{
  padding: 5px;
  background-color: #363535;
}

.dark-popover .popover-inner {
  color: #fff; /* Light text */
  border: 0.5px solid;
  border-radius : 25px !important;
}

.dark-popover .popover-header {
  background-color: #e92200 !important;
}

.dark-popover .popover-body {
  background-color: #363535 !important;
  color: #fff; /* Light text */
  padding: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dark-popover {
  min-width: 310px;
  left: -15px !important;
}

.dark-popover .popover-arrow {
  left: 15px !important;
}

.dark-popover .list-group {
  max-height: 70vh;
  overflow: auto;
}

.dark-popover .list-group-item {
  background-color: #1f1f1f;
  color: #ffffff;
}

.dark-popover .active {
  background-color: #87392b;
  color: #ffffff;
}



/* Custom styles for the react-select component */

.react-select-container .react-select__menu-list {
  height: 16vh !important;
}

.react-select-container .react-select__option {
  padding: 4px 10px;
}

.react-select-container-chat .react-select__menu-list {
  max-height:  30vh !important;
}



/* Modal CSS */
.modal-xl {
  max-width: 1280px !important;
}

.btn-close{
  font-size: 1.7rem;
  margin-top: -25px !important;
  margin-right: -15px !important;
  --bs-btn-close-bg: none;
}

.btn-close:focus, .btn-close:focus-visible{
  box-shadow: none;
}



/* Bootstrap dismissible alert  */
.btn-alert-close{
  font-size: 10px;
  top: 45% !important;
  right: 15px !important;
}



/* Swal Alert Customization */
.swal2-popup{
  display: grid;
  background: #111111;
  color: white;
  border: 1px solid gray;
}


/* Input Customization  */

input::placeholder {
  color: lightgray !important;
}

textarea::placeholder {
  color: lightgray !important;
}

.input-group-xs input::placeholder {
  font-size: 9px !important;
}

input[type="radio"],
input[type="checkbox"] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

/* Chrome, Safari, Edge, Opera */
.coin-input-custom-height::-webkit-outer-spin-button,
.coin-input-custom-height::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.coin-input-custom-height[type=number] {
  -moz-appearance: textfield;
}

.chip-input-custom-height::placeholder {
  font-size: 9px !important;
}

.coin-input-custom-height::placeholder {
  font-size: 12px !important;
}



/** Drawer css start */
.css-iur3w5-MuiPaper-root-MuiDrawer-paper{
  background: #232222 !important;
}



/* Shopify Product start*/
.shopify-buy-frame{
  padding: 0px 15px;
  margin-bottom: 15px;
}




/* Scrollbar Customization */
@media (min-width:610px) {
  ::-webkit-scrollbar {
    width: 0.6em;
    height: 0.5em;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(128, 128, 128, 0.478);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #f8c4c428;
    /* outline: 1px solid rgb(106, 106, 106); */
    border-radius: 1px;
  }
}


/* Other Customization */
label{
  margin-bottom: 0.5rem;
}

.form-check-label{
  margin-bottom: unset !important;
}


/* Utils for Layouts */

.fill-view{
  min-height: calc(100vh - 70px);
}

.layout-mt-60 {
  padding-top: 60px;
}

.layout-mt-65 {
  padding-top: 65px;
}

.layout-mt-65-100 {
  padding-top: 65px;
}

.layout-mt-112 {
  padding-top: 112px;
}

.layout-mt-75-112 {
  padding-top: 112px;
}

@media only screen and (max-width: 767px) {
  .layout-mt-65-100 {
    padding-top: 110px;
  }

  .layout-mt-75-112 {
    padding-top: 75px;
  }
}