
/* ==============
  Menu
===================*/
.container-fluid {
  max-width: 1400px;
}

#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  background: linear-gradient(90deg, #1b1a1a, #282525, #252222, #181818);
  border: 0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  height: 60px;

  .topbar-main {
    .logo {
      line-height: 57px;
      float: left;
      .logo-large {
          height: 40px;
      }
    } 

    .logo-small {
        display: none;
        height: 40px;
    }
  }
}

.topbar-custom {
  .nav-link {
    line-height: 60px;
    display: block;
    max-height: 60px;
    color: rgba($white,0.7);
  }

  .dropdown-toggle {
    &:after {
      content: initial;
    }
  }
}

.profile-dropdown {
  width: 170px;
  top: 15px !important;
  i {
      font-size: 17px;
      vertical-align: middle;
      margin-right: 5px;
      color: $gray-600;
  }
}

.nav-user {
  img {
      height: 39px;
      width: 39px;
  }
}

.arrow-none{
  &:after {
    border: none;
    margin: 0;
    display: none;
  }
}

/************* page title *************/

/*
  Responsive Menu
*/

@media (max-width: 991px) {
  #navigation {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    background-color: $white;
    &.open {
      display: block;
      overflow-y: auto;
    }
  }
}

@media (max-width: 620px) {
  .logo-large {
      display: none;
  }
  .logo-small {
      display: inline-block !important;
  }
}



.footer {
  padding: 20px;
  color: white;
  background-color: #0c0c0ce6;
}
