// Color variables
$white: #ffffff;

$primary: #626ed4;
$success: #02a499;
$info: #38a4f8;
$warning: #f8b425;
$danger: #ec4561;
$dark: #2a3142;
$muted: #9ca8b3;


// Gray Colors
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
$gray-800:                  #343a40;
$gray-900:                  #212529;